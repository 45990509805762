import "./App.css";
import NavBar from "./Navbar";
import Buttons from "./Buttons";
import CA from "./CA";
import Icons from "./Icons";

function App() {
  return (
    <div className="App bg-hans font-comic min-h-screen min-w-full flex flex-col gap-8">
      <NavBar />
      <Buttons />
      <div className="flex justify-center">
        <img className="w-72 border-4" src="hans.jpg" alt="Hans" />
      </div>
      <p className='text-2xl'>Meet Hans. Have diamond hans, or else catch these hans.</p>
      <CA/>
      <Icons/>
    </div>
  );
}

export default App;
