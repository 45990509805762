import React from "react";
import "./App.css";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const NavBar = () => {
  return (
   <div>
    <h1 className="text-7xl pt-6">HANS</h1>
   </div>
  );
};

export default NavBar;
