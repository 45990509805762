import React from 'react'

const Buttons = () => {
  return (
    <div className="flex justify-center gap-12 text-3xl">
      <a href="" target="_blank" className="hover:underline">
        Buy
      </a>
      <a href="" target="_blank" className="hover:underline">
        Chart
      </a>
      <a
        className="hover:underline"
        target="_blank"
        href="https://t.me/diamondhanstg"
      >
        Telegram
      </a>
      <a
        className="hover:underline"
        target="_blank"
        href="https://x.com/diamondhansdog"
      >
        Hans on X
      </a>
    </div>
  );
}

export default Buttons