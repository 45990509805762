import React from 'react'
import {
  FaTelegramPlane,
  FaTwitter,
  FaInstagram,
  FaDiscord,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Icons = () => {
  return (
    <div className='flex gap-8 justify-center items-center'>
      <a
        href="https://x.com/diamondhansdog"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaXTwitter className="w-12 h-12" />
      </a>

      <a
        href="https://t.me/diamondhanstg"
        target="_blank"
        rel="noopener noreferrer"
        className="text-3xl"
      >
        <FaTelegramPlane className="w-12 h-12" />
      </a>
    </div>
  );
}

export default Icons